<template>
  <div class="dashboard-slider-tags">
    <div
      class="scroll-btn"
      @click="scrollLeft"
    >
      <v-icon> mdi-chevron-left </v-icon>
    </div>
    <div class="content-wrapper">
      <v-card
        v-for="(i, tag) in items"
        :key="tag"
        :loading="isLoading"
        class="Tag_card"
      >
        <div>
          <label for="">{{ tag ? tag.replace(/_/g, " ") : "--" }}</label>
          <v-divider />
          <span>{{ i }}</span>
          <!-- <label> (Avg)</label> -->
        </div>
      </v-card>
    </div>
    <div
      class="scroll-btn"
      @click="scrollRight"
    >
      <v-icon> mdi-chevron-right </v-icon>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    scrollLeft() {
      document
        .getElementsByClassName("content-wrapper")[0]
        .scrollTo({ left: 0, behavior: "smooth" });
    },
    scrollRight() {
      const t = document.getElementsByClassName("content-wrapper")[0];
      // const w = t.offsetWidth;
      const sl = t.scrollLeft + 250;
      t.scrollTo({ left: sl, behavior: "smooth" });
    },
  },
};
</script>
<style lang="sass" scoped>
.dashboard-slider-tags
  // margin-top: 2rem
  user-select: none !important
  display: flex
  justify-content: space-between
  .scroll-btn
    cursor: pointer
    flex-basis: 30px
    display: flex
    justify-content: center
    align-items: center
    &:hover
      background: #e5e2e2 !important

  .content-wrapper
    // height: 100px
    flex: 1
    padding: 1rem 1.5rem
    display: flex
    align-items: center
    gap: 1rem
    overflow-x:  auto

    // .Tag_card div:first-child
    //   padding: 0.5rem 1rem

    .Tag_card
      height: fit-content
      min-width: 170px
      margin: 0
      cursor: pointer
      transition: 0.7s all ease
      div
        padding: 0.5rem 1rem
      &:hover
        color: #20a390
        box-shadow: 2px 1px 8px #20a390 !important
        transform : scale3d(1.2, 1.3, 1.9)
        z-index: 2
        // height: 100px
      label
        font-size: 12px
        text-transform: capitalize
      hr
        background: green
      span
        font-size: 16px
        font-weight: 600

// .content-wrapper
//   *::-webkit-scrollbar
//     width: 30px
// //  -ms-overflow-style: none !important
// //     scrollbar-width: none !important
// //     ::-webkit-scrollbar
// //       display: none !important
</style>
<style scoped>
.content-wrapper::-webkit-scrollbar {
  display: none !important;
  /* width: 12px; */
}

/* .content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
} */
</style>
